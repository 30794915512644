<template>
  <div class="sing-page-container" :class="{ 'layout-border': !isReviewPage }">
    <SingPage
      :singList="singList"
      :songInfo="songInfo"
      :timeStampList="timeStampList"
      :isReviewPage="isReviewPage"
    ></SingPage>
  </div>
</template>
<script>
import SingPage from "@/components/Course/CoursePage/SingPage";

export default {
  props: {
    isReviewPage: {
      type: Boolean,
    },
  },
  components: {
    SingPage,
  },
  data() {
    return {
      songInfo: {
        songUrl: require("@/assets/audio/songs/lesson-17.mp3"),
      },
      timeStampList: [8.1, 12.1, 16.1, 20.1, 24.3, 28.2, 33.0],
      singList: {
        lyricsArr: [
          {
            id: 1,
            spelling: "Nǐ hǎo! Nǐ shì shéi?",
            chineseWords: "你好！你是谁？",
          },
          {
            id: 2,
            spelling: "Nǐ hǎo! Wǒ jiào Xiǎotiān.",
            chineseWords: "你好！我叫小天。",
          },
          {
            id: 3,
            spelling: "Nǐ hǎo! Nǐ shì shéi?",
            chineseWords: "你好！你是谁？",
          },
          {
            id: 4,
            spelling: "Nǐ hǎo! Wǒ jiào Dōngdōng.",
            chineseWords: "你好！我叫冬冬。",
          },
          {
            id: 5,
            spelling: "Nǐ hǎo! Tā shì shéi?",
            chineseWords: "你好！她是谁？",
          },
          {
            id: 6,
            spelling: "Nǐ hǎo! Tā shì Wǒ mèimei!",
            chineseWords: "你好！她是我妹妹！",
          },
        ],
      },
    };
  },
};
</script>
<style lang="scss" scoped>
.singpage-container {
  width: calc(100% - 20px);
  height: calc(100% - 40px);
}
</style>
